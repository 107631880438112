import { orderBy } from 'lodash';

import type { IManufacturer } from '../types';

export function sortManufacturer(
  items?: { [key: string]: IManufacturer },
  topNames?: string[]
): IManufacturer[] {
  if (!items) {
    return [];
  }

  if (!topNames) {
    return orderBy(
      items,
      [ item => (item.key === 'unbekannt' ? Infinity : item.key) ],
      [ 'asc' ]
    );
  }

  return orderBy(
    items,
    [
      item => {
        if (item.key === 'unbekannt') {
          return Infinity; // Explicitly make "unbekannt" the last
        }

        const index = topNames.indexOf(item.key);

        return index === -1 ? topNames.length : index; // Items not in topNames go to the end
      },
      'key'
    ],
    [
      'asc', // Sort by topNames priority first
      'asc'  // Then sort alphabetically by key
    ]
  );
}
