import { map } from 'lodash';
import type { FC } from 'react';
import React from 'react';
import { useContext } from 'react';

import ManufacturerItem from '../../../components/ManufacturerItem';
import ConfigVosContext from '../../../service/context/config-vos';
import useOrderStoreVos from '../../../service/store/orderVos';
import { sortManufacturer } from '../../../utils/sorting';

import './ManufacturerList.scss';

const ManufacturerList: FC = () => {
  const appContext = useContext(ConfigVosContext);
  const { manufacturers_ext, hero_manufacturers } = appContext || {};
  const manufacturerOptions = sortManufacturer(manufacturers_ext, hero_manufacturers);
  const {
    manufacturer
  } = useOrderStoreVos(state => state.order) || {};
  const setManufacturer = useOrderStoreVos(state => state.setManufacturer);

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ): void => {
    const { name, value } = event.target;
    const parsedValue = parseInt(value, 10);
    const updatedManufacturerData = { ...manufacturer };

    if (parsedValue && parsedValue > 0) {
      updatedManufacturerData[name] = parsedValue;
    } else {
      delete updatedManufacturerData[name];
    }

    setManufacturer(updatedManufacturerData);
  };

  return (
    <div className="manufacturer-list-select">
      {manufacturerOptions && map(manufacturerOptions, manufacturerItem => (
        <ManufacturerItem
          image={manufacturerItem.key}
          key={manufacturerItem.name}
          title={manufacturerItem.name}
          info={manufacturerItem.info}
          value={manufacturer?.[manufacturerItem.name] || 0}
          onChange={handleInputChange}
        />
      ))}
    </div>
  );
};

export default ManufacturerList;
