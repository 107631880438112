import type { FC } from 'react';
import React from 'react';

import { UNKNOWN } from '../utils/constants';

import './ManufacturerItem.scss';
import Image from './ui/Image';
import InputNumber from './ui/InputNumber';

interface IManufacturerItem {
  title: string;
  image?: string;
  info?: string;
  value: number;
  onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
}

const ManufacturerItem: FC<IManufacturerItem> = ({
  title,
  image,
  info,
  onChange,
  value
}) => {
  return (
    <div>
      <div className="manufacturer-count-item">
        <div className="title">
          {image && image !== UNKNOWN ? (
            <Image
              url={`${image}.svg`}
              alt={title}
              type="manufacturer"
            />
          ) : (
            <>{title}</>
          )}
        </div>
        <div className="number">
          <InputNumber
            value={value}
            name={title}
            onChange={onChange}
            useSmall
          />
        </div>
      </div>
      {info && (
        <div className="manufacturer-count-item-info">
          {info}
        </div>
      )}
    </div>
  );
};

export default ManufacturerItem;
